<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text ml-1" style="color: #FFFFFF">
          {{ $t('Global.Name') }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-0">
        <BImg class="bg" :src="require('@/assets/images/logo/login.png')" />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t('Auth.Login.WelcomeText') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Auth.Password.PasswordInvitation') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group :label="$t('Auth.Login.Username')" label-for="_">
                <b-form-input :value="username" disabled />
              </b-form-group>
              <b-form-group
                :label="$t('Auth.Login.Password')"
                label-for="login-password"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Auth.Login.Password')"
                  rules="required|min:8|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      placeholder="············"
                      class="form-control-merge"
                      autocomplete="off"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('Auth.Password.Confirm')"
                label-for="ac-password"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Auth.Password.Confirm')"
                  :rules="`required|confirmed:${$t('Auth.Login.Password')}`"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="ac-password"
                      v-model="confirmPassword"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      placeholder="············"
                      autocomplete="off"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t('Auth.Login.Login') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, min, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import useJwt from '@/auth/jwt/useJwt'
import { parseJwt } from '@/auth/utils'
import { updateAbility } from '@/libs/acl/ability'
import { RolesEnum } from '@/data/enums'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'height-fade': heightFade,
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    password: '',
    confirmPassword: '',

    required,
    min,
    confirmed,
  }),
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    username() {
      const { token } = this.$route.params
      if (token == null) return ''

      const json = parseJwt(token)

      return json.username
    },
  },
  mounted() {
    const { token } = this.$route.params
    if (token == null) this.$router.push({ name: 'login' })
  },
  methods: {
    async validationForm() {
      const validation = await this.$refs.loginValidation.validate()

      if (!validation) return

      try {
        const { token } = this.$route.params
        const { data } = await this.$axios.post('auth/set-password', {
          password: this.password,
          token,
        })
        const { accessToken, user, refreshToken } = data

        if (user.role === RolesEnum.Employee) throw new Error()

        useJwt.setToken(accessToken)
        useJwt.setRefreshToken(refreshToken)
        localStorage.setItem('user', JSON.stringify(user))
        this.$ability.update(updateAbility(user.role))

        this.$router.replace({ name: 'home' })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Auth.Login.Welcome', {
              name:
                user.firstname && user.lastname
                  ? `${user.firstname} ${user.lastname}`
                  : user.username,
            }),
            icon: 'CoffeeIcon',
            text: this.$t('Auth.Login.WelcomeSuccess', {
              role: this.$t(`Auth.Roles.${user.role}`),
            }),
          },
        })
      } catch (error) {
        this.hasError = true

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Auth.Login.LoginError'),
            icon: 'AlertTriangleIcon',
            text: this.$t('Auth.Login.Error'),
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.bg {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
</style>
